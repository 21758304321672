import Service from '../models/Service'

export const state = () => ({
  services: [],
})

export const getters = {
  services: (state) => {
    return state.services
  },
}

export const mutations = {
  setServices(state, services) {
    state.services = services
  },
}

export const actions = {
  async fetchServices({ commit }) {
    const services = await Service.include('media').where('root', true).get()
    commit('setServices', services.data)
    return services.data
  },
  async fetchService({ commit }, slug) {
    const service = await Service.include(
      'media',
      'parent',
      'prices',
      'reservations',
      'children',
      'children.media',
      'children.prices',
      'children.reservations'
    ).find(slug)
    return service.data
  },
}
