import MapIcon from '../models/MapIcon'

export const state = () => ({
  icons: [],
})

export const getters = {
  icons: (state) => {
    return state.icons
  },
}

export const mutations = {
  setIcons(state, icons) {
    state.icons = icons
  },
}

export const actions = {
  async fetchIcons({ commit }) {
    const icons = await MapIcon.include('servicesActive', 'media').get()
    commit('setIcons', icons.data)
    return icons.data
  },
}
