import Slider from '../models/Slider'

export const state = () => ({
  sliders: [],
})

export const getters = {
  sliders: (state) => {
    return state.sliders
  },
}

export const mutations = {
  setSliders(state, sliders) {
    state.sliders = sliders
  },
}

export const actions = {
  async fetchSliders({ commit }) {
    const sliders = await Slider.include('media').get()
    commit('setSliders', sliders.data)
    return sliders.data
  },
}
