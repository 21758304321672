export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"Latviešu","code":"lv","iso":"lv-LV","file":"lv.js"},{"name":"English","code":"en","iso":"en","file":"en.js"},{"name":"Русский","code":"ru","iso":"ru-RU","file":"ru.js"},{"name":"Deutsche","code":"de","iso":"de-DE","file":"de.js"},{"name":"Lietùvių kalbà","code":"lt","iso":"lt-LT","file":"lt.js"}],
  defaultLocale: "lv",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Latviešu","code":"lv","iso":"lv-LV","file":"lv.js"},{"name":"English","code":"en","iso":"en","file":"en.js"},{"name":"Русский","code":"ru","iso":"ru-RU","file":"ru.js"},{"name":"Deutsche","code":"de","iso":"de-DE","file":"de.js"},{"name":"Lietùvių kalbà","code":"lt","iso":"lt-LT","file":"lt.js"}],
  localeCodes: ["lv","en","ru","de","lt"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "l",
  3: "v",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "l",
  19: "v",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "e",
  27: "n",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "e",
  43: "n",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: ",",
  49: "\"",
  50: "r",
  51: "u",
  52: ".",
  53: "j",
  54: "s",
  55: "\"",
  56: ":",
  57: "\"",
  58: ".",
  59: ".",
  60: "/",
  61: "l",
  62: "a",
  63: "n",
  64: "g",
  65: "/",
  66: "r",
  67: "u",
  68: ".",
  69: "j",
  70: "s",
  71: "\"",
  72: ",",
  73: "\"",
  74: "d",
  75: "e",
  76: ".",
  77: "j",
  78: "s",
  79: "\"",
  80: ":",
  81: "\"",
  82: ".",
  83: ".",
  84: "/",
  85: "l",
  86: "a",
  87: "n",
  88: "g",
  89: "/",
  90: "d",
  91: "e",
  92: ".",
  93: "j",
  94: "s",
  95: "\"",
  96: ",",
  97: "\"",
  98: "l",
  99: "t",
  100: ".",
  101: "j",
  102: "s",
  103: "\"",
  104: ":",
  105: "\"",
  106: ".",
  107: ".",
  108: "/",
  109: "l",
  110: "a",
  111: "n",
  112: "g",
  113: "/",
  114: "l",
  115: "t",
  116: ".",
  117: "j",
  118: "s",
  119: "\"",
  120: "}",
}

export const localeMessages = {
  'lv.js': () => import('../../lang/lv.js' /* webpackChunkName: "lang-lv.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
  'ru.js': () => import('../../lang/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
  'lt.js': () => import('../../lang/lt.js' /* webpackChunkName: "lang-lt.js" */),
}
