import { localize } from 'vee-validate'

function loadLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then((locale) => {
    localize(code, locale)
  })
}

export default function ({ i18n, store }) {
  const locale = i18n.locale
  loadLocale(locale)
  store.commit('reservations/setUserLocale', locale)

  i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    loadLocale(newLocale)
  }
  i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    store.commit('reservations/setUserLocale', newLocale)
    store.dispatch('settings/fetchSettings')
  }
}
