import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03492aa4 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _f41988ba = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3493b267 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6482cb9e = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _38919674 = () => interopDefault(import('../pages/tourist-attractions/index.vue' /* webpackChunkName: "pages/tourist-attractions/index" */))
const _a8845dac = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _10ce41ab = () => interopDefault(import('../pages/tourist-attractions/_attraction/index.vue' /* webpackChunkName: "pages/tourist-attractions/_attraction/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _03492aa4,
    name: "about-us___lv"
  }, {
    path: "/contacts",
    component: _f41988ba,
    name: "contacts___lv"
  }, {
    path: "/de",
    component: _e542f99c,
    name: "index___de"
  }, {
    path: "/en",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/lt",
    component: _e542f99c,
    name: "index___lt"
  }, {
    path: "/privacy-policy",
    component: _3493b267,
    name: "privacy-policy___lv"
  }, {
    path: "/ru",
    component: _e542f99c,
    name: "index___ru"
  }, {
    path: "/services",
    component: _6482cb9e,
    name: "services___lv"
  }, {
    path: "/tourist-attractions",
    component: _38919674,
    name: "tourist-attractions___lv"
  }, {
    path: "/de/about-us",
    component: _03492aa4,
    name: "about-us___de"
  }, {
    path: "/de/contacts",
    component: _f41988ba,
    name: "contacts___de"
  }, {
    path: "/de/privacy-policy",
    component: _3493b267,
    name: "privacy-policy___de"
  }, {
    path: "/de/services",
    component: _6482cb9e,
    name: "services___de"
  }, {
    path: "/de/tourist-attractions",
    component: _38919674,
    name: "tourist-attractions___de"
  }, {
    path: "/en/about-us",
    component: _03492aa4,
    name: "about-us___en"
  }, {
    path: "/en/contacts",
    component: _f41988ba,
    name: "contacts___en"
  }, {
    path: "/en/privacy-policy",
    component: _3493b267,
    name: "privacy-policy___en"
  }, {
    path: "/en/services",
    component: _6482cb9e,
    name: "services___en"
  }, {
    path: "/en/tourist-attractions",
    component: _38919674,
    name: "tourist-attractions___en"
  }, {
    path: "/lt/about-us",
    component: _03492aa4,
    name: "about-us___lt"
  }, {
    path: "/lt/contacts",
    component: _f41988ba,
    name: "contacts___lt"
  }, {
    path: "/lt/privacy-policy",
    component: _3493b267,
    name: "privacy-policy___lt"
  }, {
    path: "/lt/services",
    component: _6482cb9e,
    name: "services___lt"
  }, {
    path: "/lt/tourist-attractions",
    component: _38919674,
    name: "tourist-attractions___lt"
  }, {
    path: "/ru/about-us",
    component: _03492aa4,
    name: "about-us___ru"
  }, {
    path: "/ru/contacts",
    component: _f41988ba,
    name: "contacts___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _3493b267,
    name: "privacy-policy___ru"
  }, {
    path: "/ru/services",
    component: _6482cb9e,
    name: "services___ru"
  }, {
    path: "/ru/tourist-attractions",
    component: _38919674,
    name: "tourist-attractions___ru"
  }, {
    path: "/de/services/:service",
    component: _a8845dac,
    name: "services-service___de"
  }, {
    path: "/de/tourist-attractions/:attraction",
    component: _10ce41ab,
    name: "tourist-attractions-attraction___de"
  }, {
    path: "/en/services/:service",
    component: _a8845dac,
    name: "services-service___en"
  }, {
    path: "/en/tourist-attractions/:attraction",
    component: _10ce41ab,
    name: "tourist-attractions-attraction___en"
  }, {
    path: "/lt/services/:service",
    component: _a8845dac,
    name: "services-service___lt"
  }, {
    path: "/lt/tourist-attractions/:attraction",
    component: _10ce41ab,
    name: "tourist-attractions-attraction___lt"
  }, {
    path: "/ru/services/:service",
    component: _a8845dac,
    name: "services-service___ru"
  }, {
    path: "/ru/tourist-attractions/:attraction",
    component: _10ce41ab,
    name: "tourist-attractions-attraction___ru"
  }, {
    path: "/services/:service",
    component: _a8845dac,
    name: "services-service___lv"
  }, {
    path: "/tourist-attractions/:attraction",
    component: _10ce41ab,
    name: "tourist-attractions-attraction___lv"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___lv"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
