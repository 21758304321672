
export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
