import Model from './Model'

export default class Country extends Model {
  resource() {
    return 'countries'
  }

  primaryKey() {
    return 'id'
  }
}
