import Reservation from '../models/Reservation'

function daysFromNowDate(days = 1) {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() + days)
  return date
}

export const state = () => ({
  reservation: {
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    country_id: null,
    additional_info: null,
    check_in_date: daysFromNowDate(1),
    check_out_date: daysFromNowDate(2),
    services: [],
    total_price: 0,
    user_locale: process.env.LOCALE,
  },
  date: new Date(),
})

export const getters = {
  reservation: (state) => {
    return state.reservation
  },
  service: (state) => (id) => {
    return state.reservation.services.find(
      (service) => service.service_id === id
    )
  },
  checkInDate: (state) => {
    return state.reservation.check_in_date
  },
  checkOutDate: (state) => {
    return state.reservation.check_out_date
  },
}

export const mutations = {
  setReservation(state, reservation) {
    state.reservation = reservation
  },
  clearReservationServices(state) {
    state.reservation.services = []
  },
  removeService(state, service) {
    const index = state.reservation.services.findIndex((resService) => {
      return resService.service_id === service.id
    })
    if (index > -1) {
      state.reservation.services.splice(index, 1)
    }
  },
  updateService(state, { service, data }) {
    let index = -1
    if (state.reservation.services.length > 0) {
      index = state.reservation.services.findIndex((serv) => {
        return serv.service_id === service.id
      })
    }

    /* eslint-disable dot-notation */
    if (index > -1) {
      state.reservation.services[index]['title'] = service.title
      state.reservation.services[index]['price'] = data.price
      state.reservation.services[index]['quantity'] = data.quantity
      state.reservation.services[index]['total_price'] = data.total_price
    } else if (data !== undefined) {
      data['service_id'] = service.id
      data['title'] = service.title
      state.reservation.services.push(data)
    }
  },
  updateTotalPrice(state) {
    let totalPrice = 0
    state.reservation.services.forEach((service) => {
      totalPrice += service.total_price
    })
    state.reservation.total_price = totalPrice
  },
  setCheckInDate(state, date) {
    state.reservation.check_in_date = date
  },
  setCheckOutDate(state, date) {
    state.reservation.check_out_date = date
  },
  setUserLocale(state, locale) {
    state.reservation.user_locale = locale
  },
}

export const actions = {
  async submitReservation({ state }) {
    if (state.reservation.services.length === 0) {
      throw this.$i18n.t('reservation.form.no_services');
    }
    const reservation = new Reservation(state.reservation)
    await reservation.save()
    return reservation
  },
  clearReservationServices({ commit }) {
    commit('clearReservationServices')
    commit('updateTotalPrice')
  },
  updateService({ commit }, { service, payload }) {
    if (payload.quantity <= 0) {
      commit('removeService', service)
    } else {
      commit('updateService', { service, data: payload })
    }
    commit('updateTotalPrice')
  },
  removeService({ commit }, service) {
    commit('removeService', service)
    commit('updateTotalPrice')
  },
}
