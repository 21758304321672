export const state = () => ({
  settings: [],
})

export const getters = {
  settings: (state) => {
    return state.settings
  },
  setting: (state) => (key) => {
    const setting = state.settings.data.find((todo) => todo.key === key)
    if (setting) {
      return setting.value
    }
  },
}

export const mutations = {
  setSettings(state, settings) {
    state.settings = settings
  },
}

export const actions = {
  async fetchSettings({ commit }) {
    const settings = await this.$axios.get('/settings')
    commit('setSettings', settings.data)
    return settings.data
  },
}
