export const state = () => ({
  currency: {
    curr: 'EUR',
    symbol: '€',
    ratio: 1,
  },
})

export const getters = {
  getCurrency: (state) => {
    return state.currency
  },
}

export const mutations = {
  setCurrency: (state, payload) => {
    state.currency = payload
  },
}
