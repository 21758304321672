import Model from './Model'

export default class Reservation extends Model {
  resource() {
    return 'reservations'
  }

  primaryKey() {
    return 'id'
  }
}
