import Country from '../models/Country'

export const state = () => ({
  countries: [],
})

export const getters = {
  countries: (state) => {
    return state.countries
  },
}

export const mutations = {
  setCountries(state, countries) {
    state.countries = countries
  },
}

export const actions = {
  async fetchCountries({ commit }) {
    const countries = await Country.get()
    commit('setCountries', countries.data)
    return countries.data
  },
}
