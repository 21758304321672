
import {
  ArrowUpIcon
} from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  components: {
    ArrowUpIcon
  },
  data () {
    return {
      scroll_to_top_visible: false,
      cookies: this.$cookies
    }
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    let siteTitle = process.env.APP_NAME
    const settingTitle = this.setting('site_title')
    if (typeof settingTitle !== "undefined") {
      siteTitle = settingTitle
    }
    return {
      htmlAttrs: {
        amp: true,
        ...i18nHead.htmlAttrs
      },
      title: siteTitle,
      titleTemplate: '%s - ' + siteTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.setting('site_description ') || ''
        },
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    }
  },
  computed: {
    ...mapGetters({
      setting: 'settings/setting'
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      setTimeout(() => this.$nuxt.$loading.finish(), 3000)
    })
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollTop () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener (e) {
      this.scroll_to_top_visible = window.scrollY > 150
    }
  }
}
