import Vue from 'vue'

export default ({ i18n }) => {
  Vue.filter('currency', function (value, currency) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat(i18n.locale, {
      style: 'currency',
      currency,
    })
    return formatter.format(value)
  })

  Vue.filter('uppercase', function (value) {
    return value.toUpperCase()
  })
}
