import Model from './Model'

export default class Service extends Model {
  resource () {
    return 'services'
  }

  primaryKey () {
    return 'slug'
  }
}
