
export default {
  data() {
    return {
      saved: true,
      cookies: this.$cookies
    }
  },

  computed: {
    expirationDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return date.toUTCString();
    },

    optionalCookies() {
      return this.cookies.optional;
    }
  },

  watch: {
    locale() {
      this.setTexts(true);
    }
  },

  beforeMount() {
    this.setTexts();
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.browser && this.cookies.colors) {
      let key = null;
      const variables = {};
      for (key in this.cookies.colors) {
        const k = key.toLowerCase().includes('unactive') ? key.replace(/Unactive/g, 'Inactive') : key;
        variables[`cookie-control-${k}`] = `${this.cookies.colors[key]}`
      }

      for (const cssVar in variables) {
        document.documentElement.style.setProperty(`--${cssVar}`, variables[cssVar]);
      }
    }

    if (!this.cookies.get('cookie_control_consent') || this.cookies.get('cookie_control_consent').length === 0) {
      this.optionalCookies.forEach(c => {
        if (c.initialState === true) {
          this.cookies.enabledList.push(c.identifier || this.cookies.slugify(this.getCookieFirstName(c.name)));
        }
      })
    }
    this.colorsSet = true;
  },

  methods: {
    toogleCookie(cookie) {
      const cookieName = cookie.identifier || this.cookies.slugify(this.getCookieFirstName(cookie.name));
      if (this.saved) this.saved = false;
      if (!this.cookies.enabledList.includes(cookieName)) this.cookies.enabledList.push(cookieName);
      else this.cookies.enabledList.splice(this.cookies.enabledList.indexOf(cookieName), 1);
    },

    setConsent({type, consent = true, reload = true, declineAll = false}) {
      this.cookies.set({name: 'cookie_control_consent', value: consent, expires: this.expirationDate});
      const enabledCookies = declineAll ? [] : type === 'partial' && consent ? this.cookies.enabledList : [...this.optionalCookies.map(c => c.identifier || this.cookies.slugify(this.getCookieFirstName(c.name)))];
      this.cookies.set({name: 'cookie_control_enabled_cookies', value: consent ? enabledCookies.join(',') : '', expires: this.expirationDate});
      if (!reload) {
        this.cookies.setConsent()
        this.$cookies.modal = false;
      } else window.location.reload(true);
    },

    getDescription(description) {
      if (typeof (description) === 'string') return ` ${this.cookies.dashInDescription !== false ? '-' : ''} ${description}`;
      else if (description[this.locale]) return ` ${this.cookies.dashInDescription !== false ? '-' : ''} ${description[this.locale]}`;
      return '';
    },

    getName(name) {
      return name === 'functional' ? this.cookies.text.functional : typeof (name) === 'string' ? name : name[this.locale] ? name[this.locale] : name[Object.keys(name)[0]];
    },

    getCookieFirstName(name) {
      return typeof (name) === 'string' ? name : name[Object.keys(name)[0]]
    },

    setTexts(isChanged = false) {
      const text = {};
      if (this.cookies.text && Object.keys(this.cookies.text).length > 0) {
        if (this.cookies.text.locale) {
          Object.assign(text, this.cookies.text.locale[this.$i18n.locale])
        }
        if (!isChanged) Object.assign(text, this.cookies.text)
      }
      this.$set(this.$cookies, 'text', text);
    },

    showCookiesModal() {
      this.cookies.modal = true
    },
  }
}
