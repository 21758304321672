import TouristAttraction from '../models/TouristAttraction'

export const state = () => ({
  attractions: [],
})

export const getters = {
  attractions: (state) => {
    return state.attractions
  },
}

export const mutations = {
  setAttractions(state, attractions) {
    state.attractions = attractions
  },
}

export const actions = {
  async fetchAttractions({ commit }) {
    const attractions = await TouristAttraction.include('media').get()
    commit('setAttractions', attractions.data)
    return attractions.data
  },
  async fetchAttraction({ commit }, slug) {
    const attraction = await TouristAttraction.include('media').find(slug)
    return attraction.data
  },
}
