import Model from './Model'

export default class TouristAttraction extends Model {
  resource () {
    return 'tourist-attractions'
  }

  primaryKey () {
    return 'slug'
  }
}
