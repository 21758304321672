import Model from './Model'

export default class Slider extends Model {
  resource () {
    return 'sliders'
  }

  primaryKey () {
    return 'id'
  }
}
